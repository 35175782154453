import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const projects = [
	{
		projectName: "ArtRoom",
		businessDescription: "AI Art generating platform",
		text:
			"Immerse yourself in our cutting-edge AI Art generating platform, where you can unleash your creativity and bring your artistic visions to life like never before. " +
			"Create new images, edit existing ones, enhance them, and improve the quality with the assistance of our advanced AI algorithms.",
		projectScreenshot: <StaticImage src="../images/screenshot-artroom.png" alt="ArtRoom" />,
	},
	{
		projectName: "HIPPOWORLD",
		businessDescription: "Structured domain information for enriched intelligence",
		text:
			"Email address verification technology from Email Hippo that connects to mailboxes and checks whether an email address exists. " +
			"Access consistent, reliable WHOIS data via an easy to call API that unwraps 2,500 sources.\n" +
			"\n" +
			"WHOIS provides machine readable domain data, including calculated domain ages and time to expiry.",
		projectScreenshot: <StaticImage src="../images/screenshot-hippo.png" alt="HippoWorld" />,
	},
	{
		projectName: "Microting Eform v2",
		businessDescription: "Business’s paper forms replacer v2",
		text: "Experience how easy it is to digitalize all your business paper forms and replace them with user friendly Microting eForms. Afterwards, all data collection will be on your Tablet or Smartphone. Easily integrate this with your ERP - system.",
		projectScreenshot: <StaticImage src="../images/screen.png" alt="Eform v2" />,
	},
	{
		projectName: "SALINA CODE ACADEMY",
		businessDescription: "Сoding classes for kids",
		text: "Salina Code Academy provides hands on learning for elementary aged kids, middle-schoolers, and high school students, teaching them: math, logical thinking, persistence, etc.",
		projectScreenshot: <StaticImage src="../images/screenshot-ca.png" alt="Procue.tv" />,
	},
	{
		projectName: "Forstter Youth Center",
		businessDescription: "Family searching service for kids",
		text:
			"Finding long-lasting loving homes for every Kansas child.\n" +
			"We are driven, not just to improve the lives of the children and families who come to us for care, but to transform the lives of children and families",
		projectScreenshot: <StaticImage src="../images/screenshot-forstter.png" alt="Forstter" />,
	},
	{
		projectName: "BOOKCLUB",
		businessDescription: "Book readers social network",
		text:
			"Bookclub is a social app that renews your love of books. Bookclub fosters community and helps you connect with people by encouraging conversations throughout books, at every chapter along the way. " +
			"It’s more fun to revel in the details of books that you choose to read, no matter your location, favorite genre, or personal reading pace. " +
			"And you’ll encourage others to go through new books with you along the way!",
		projectScreenshot: <StaticImage src="../images/screenshot-bookclub.png" alt="Bookclub" />,
	},
	{
		projectName: "PROCUE.TV",
		businessDescription: "The Professional Solution to Remote Presentation Cueing",
		text:
			"Built from the ground up with reliability and redundancy in mind. Even if an entire datacenter where this is hosted goes offline, it will seamlessly failover to another without your show missing a beat.\n" +
			"\n" +
			"Deployed on enterprise grade servers with complete redundancy.",
		projectScreenshot: <StaticImage src="../images/screenshot-procue.png" alt="Procue.tv" />,
	},
	{
		projectName: "AREAZOOM",
		businessDescription: "Structured domain information for enriched intelligence",
		text: "Area Zoom offers a scientific solution to real estate property evaluation and tells you what a radius of a specific area is worth. With daily updates accessible for any visitor!",
		projectScreenshot: <StaticImage src="../images/screenshot-areazoom.png" alt="AreaZoom" />,
	},
	{
		projectName: "CROWDGOAT",
		businessDescription: "Split & delivery service goat meat from local farmers",
		text: "CrowdGoat is an easy and simple way to buy a whole goat or split the parts of a goat with your friends or family. Download the app now and get your fresh goat meat delivered in no time!",
		projectScreenshot: (
			<StaticImage src="../images/screenshot-crowdgoat.png" alt="HippoWorld" />
		),
	},
	{
		projectName: "PETER'S ROCK MINISTRIES",
		businessDescription: "Church events and live streams app",
		text:
			"The mission of Peter’s Rock Ministries is to glorify God, edify the saints, and evangelize the lost. More specifically, the purpose and mission of Peter’s Rock Church is to effectively minister to ALL people regardless of gender, color, creed, or religious persuasion. " +
			"We MUST minister through the Word of God and be committed to leadership in order to enhance spiritual growth and victorious living",
		projectScreenshot: (
			<StaticImage src="../images/screenshot-petersrock.png" alt="Peterscrock" />
		),
	},

	{
		projectName: "Microting Eform v1",
		businessDescription: "Business’s paper forms replacer v1",
		text: "Experience how easy it is to digitalize all your business paper forms and replace them with user friendly Microting eForms. Afterwards, all data collection will be on your Tablet or Smartphone. Easily integrate this with your ERP - system.",
		projectScreenshot: <StaticImage src="../images/eform2-full.png" alt="Eform v1" />,
	},
];
