export const teamPoints = [
	{
		title: "INDIVIDUAL APPROACH",
		description:
			"Every client is important and unique to us. We would consult with you about our every move, suggest how to improve your business, and help you to automate the business process for your individual needs.",
	},
	{
		title: "TIME PROVEN REPUTATION",
		description:
			"Completing projects in different fields, such as CRM solutions, real estate, ERP systems, mining, and insurance, we have earned our reputation on the market. Our clients are long-term and located in the United States, Lithuania, Denmark, and Great Britain.",
	},
	{
		title: "20+ SUCCESSFULLY COMPLETED PROJECTS",
		description:
			"Professionally with the care, we've completed a lot of different sizes and shapes projects for many clients.",
	},
];
