export const servicesPoints: { title: string; description: string; items: string[] }[] = [
	{
		title: "WEB DEVELOPMENT",
		description:
			"We've developed various software solutions for the most popular industries, accumulated tons of experience, and are fully ready to share it. So if you want to digitize your services, launch a startup, or update your codebase according to today's standards, our professionals will help you reach your goals and put your business onto absolutely another level.",
		items: [
			"Frontend Development",
			"Angular Development",
			"React.js Development",
			"Backend Development",
			"C#/.NET Development",
			"Node.js Development",
		],
	},
	{
		title: "MOBILE DEVELOPMENT",
		description:
			"Most people use their phones each day to surf the net and social media platforms, take a look at emails, control calendars, pay attention to music, play games, watch videos, take photos, examine the news, write textual content messages, and also, sometimes we use them for their unique purpose, to make calls. We design, develop, and implement mobile solutions similar to internet apps. In addition, our experts provide custom software development services with apps for iOS and Android.",
		items: ["React Native Development", "Flutter Framework", "Ionic with Angular and React"],
	},
	{
		title: "SOFTWARE DESIGN",
		description:
			"UX design is all about identifying and solving user problems. We design your idea in collaboration with you the way people want to interact with it, developing human-centered and research-based interfaces with the best practices.",
		items: [
			"Market Investigation",
			"Prototyping",
			"Mobile App Design",
			"Web App Design",
			"Motion Design",
			"E-commerce Solutions Design",
		],
	},
	{
		title: "CONSULTING SERVICES",
		description:
			"You can get expert help throughout all outsourced software development services, including planning. Our specialists can recommend your software platform's best architecture options and structures. Through specific consultations, you could find out how one or every other software program structure meets your business goals, aligns with your enterprise logic, and helps reach the desired goals.",
		items: [
			"Digital Transformation",
			"CTO as a Service",
			"Discovery Phase",
			"MVP for Startups",
			"Architecture Consultancy",
		],
	},
	{
		title: "DEVOPS",
		description:
			"We are actively using DevOps culture in our team. It helps us to deliver best quality both in the development process and team work by implementing CI/CD processes while developing applications.",
		items: [
			"Continuous Integration and Continuous Delivery processes",
			"DevOps Consulting",
			"Data migration",
			"System Scaling",
		],
	},
	{
		title: "QUALITY ASSURANCE",
		description:
			"QA is a crucial part of the whole development cycle, ensuring that the software is built according to requirements and industry standards.",
		items: ["Unit Tests and E2E Tests", "Functional Testing", "Performance Testing"],
	},
];
