import { extendTheme } from "@chakra-ui/react";
import { colors } from "../../constants";

const theme = {
	colors,
	fonts: {
		heading: "Gilroy ExtraBold",
		body: "Gilroy Light",
		poppins: "Poppins",
	},
	components: {
		Button: {
			baseStyle: {
				fontWeight: "normal",
				fontSize: "16px",
				lineHeight: "22px",
				borderRadius: "40px",
				textTransform: "capitalize",
				color: "white",
			},
			sizes: {
				md: {
					height: "55px",
				},
			},
			variants: {
				primary: {
					bg: colors.primary,
					_hover: {
						color: colors.primary,
						bg: "white",
					},
				},
			},
		},
	},
};

export default extendTheme(theme);
