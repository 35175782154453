export const services: { name: string; items: string[] }[] = [
	{
		name: "Software development",
		items: [
			"Web Development",
			"Mobile Development",
			"Manual/Automated Testing",
			"Quality Assurance",
			"Legacy Application Rewriting",
			"DevOps and Deployment",
			"Data Migration",
			"Support and Maintenance",
		],
	},
	{
		name: "Software design",
		items: [
			"Market Investigation",
			"Prototyping",
			"Mobile App Design",
			"Web App Design",
			"Motion Design",
			"E-commerce Solutions Design",
		],
	},
	{
		name: "Consulting service",
		items: [
			"Digital Transformation",
			"CTO as a Service",
			"Discovery Phase",
			"MVP for Startups",
			"Architecture Consultancy",
		],
	},
];
