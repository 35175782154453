import React from "react";
import SupportIcon from "../icons/support.svg";
import ProcessesIcon from "../icons/processes.svg";
import BullsEyeIcon from "../icons/bullseye.svg";
import StackIcon from "../icons/stack.svg";

export const aboutUsPoints = [
	{
		icon: <SupportIcon />,
		title: "LONG TERM SUPPORT",
		description:
			"We are focused on long-term cooperation with our customers, including (but not limited to) service and project support.",
	},
	{
		icon: <ProcessesIcon />,
		title: "PERFECT PROCESSES",
		description: "We provide the best practiceof software development and software engineering",
	},
	{
		icon: <BullsEyeIcon />,
		title: "DEDICATED TEAM",
		description:
			"You get a flexible SCRUM team with professional software engineers working for your business",
	},
	{
		icon: <StackIcon />,
		title: "WIDE TECHNOLOGY STACK",
		description: "We don't limit development using several technologies.",
	},
];
