export const industries: { name: string; items: string[] }[] = [
	{
		name: "FINTECH",
		items: [
			"Investment Platforms",
			"Lending Software",
			"Insurance Products",
			"Mortgage Products",
			"Accounting Solutions",
		],
	},
	{
		name: "LOGISTICS",
		items: [
			"Logistics Software",
			"Google Maps Integration",
			"Taxi Applications",
			"Food Delivery Solutions",
			"Car-sharing Platforms",
			"Route Optimization solutions",
			"Transporation Management systems",
		],
	},
	{
		name: "WAREHOUSING",
		items: [
			"CRM systems",
			"Supply management applications",
			"Multiple Warehouse Management Software",
			"Warehouse Connection",
		],
	},
	{
		name: "REAL ESTATE",
		items: [
			"Real Estate Marketplaces",
			"Market analysis software",
			"Property Lending Solutions",
			"Property Management Apps",
		],
	},
	{
		name: "HEALTHCARE",
		items: [
			"Professional Applications",
			"Nutrition Software",
			"Fitness Logbooks",
			"Dental Clinic Software",
		],
	},
];
