enum Links {
	Home = "/",
	Services = "/services",
	Contacts = "/contacts",
	// Cases = "/case-studies",
}

export const links = (Object.keys(Links) as Array<keyof typeof Links>).map(key => ({
	label: key,
	destination: Links[key],
}));
