export const colors = {
	primary: "#049283",
	background: "#131313",
	blurPurple: "#AD06FC",
	blurRed: "#EE0E44",
	blurBlue: "#406AFF",
	blurLightBlue: "#00D1FF",
	pointsBorder: "#1F1F1F",
	bulletPointBG: "#191919",
	bulletPointBorder: "#252525",
	bulletPointShadow: "rgba(2, 87, 78, 0.03)",
	twitterColor: "#0084B4",
	linkedInColor: "#0073B0",
};
