import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const REQUIRED = "This field is required";
const EMAIL_INVALID = "E-Mail is invalid";

const contactsSchemaResolver = yup.object().shape({
	name: yup.string().required(REQUIRED),
	text: yup.string().required(REQUIRED),
	email: yup.string().email(EMAIL_INVALID),
});

export const contactsSchema = yupResolver(contactsSchemaResolver);
